import React from 'react'

function DirectorySearchResult() {
  return (
    <div>
      directorySearchResult
    </div>
  )
}

export default DirectorySearchResult