import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ModalContext } from "./ModalContext";
import axios from "axios";
import { toast } from "react-toastify";
const FavModal = ({ prop_id, agentid, is_favourite, favouriteId }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { openAuthModal } = useContext(ModalContext);
  const [search, setSearch] = useState("");
  const [isSaved, setIsSaved] = useState(null);
  const [uid, setUid] = useState(null);
  const [favourites, setFavourites] = useState([]);
  const [favid, setFavid] = useState(null);

  useEffect(() => {
    const storedUid = localStorage.getItem("uid");
    const token = localStorage.getItem("token");

    if (storedUid && token) {
      setUid(storedUid);
      // Fetch existing favourites
    }
  }, []);
  useEffect(() => {
    if (is_favourite === "yes") {
      setFavid(favouriteId);
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
  }, []);
  //   useEffect(() => {
  //   setIsSaved(is_favourite === "yes");
  // }, [is_favourite]);

  // const fetchFavourites = (uid, token) => {
  //   axios
  //     .get(`https://backend.militaryhousingcenter.net/api/favourites/${uid}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Fetched favourites:", response.data.favourites); // Debugging
  //       setFavourites(response.data.favourites);
  //       const favorite = response.data.favourites.find(
  //         (fav) =>
  //           Number(fav.propid) === Number(prop_id) ||
  //           Number(fav.agentid) === Number(agentid) ||
  //           fav.title === search
  //       );
  //       if (favorite) {
  //         setIsSaved(true);
  //         setFavid(favorite.favid);
  //       }

  //     })
  //     .catch((error) => {
  //       console.error("Error fetching favourites:", error.response.data);

  //     });
  // };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSave = () => {
    const token = localStorage.getItem("token");

    if (!search || search.length < 3) {
      toast.warning(
        "Please enter a valid search title (at least 3 characters)."
      );
      return;
    }

    const saveData = {
      title: search,
      propid: parseInt(prop_id),
      requid: uid,
      agentid: parseInt(agentid),
    };

    if (token) {
      axios
        .post(
          "https://backend.militaryhousingcenter.net/api/favourites",
          saveData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsSaved(true);
          setFavid(response.data.favid);
          handleClose();
          toast.success("Property saved successfully!");
        })
        .catch((error) => {
          console.error("Error saving property:", error.response.data);
          toast.error(
            `Failed to save property: ${error.response.data.message}`
          );
        });
    } else {
      handleClose();
      openAuthModal();

      const authModalInterval = setInterval(() => {
        const newToken = localStorage.getItem("token");
        const newUid = localStorage.getItem("uid");

        if (newToken && newUid) {
          clearInterval(authModalInterval);
          setUid(newUid);

          axios
            .post(
              "https://backend.militaryhousingcenter.net/api/favourites",
              { ...saveData, requid: newUid },
              {
                headers: {
                  Authorization: `Bearer ${newToken}`,
                },
              }
            )
            .then((response) => {
              setIsSaved(true);
              setFavid(response.data.favid);
              toast.success("Property saved successfully after login!");
            })
            .catch((error) => {
              console.error("Error saving property:", error.response.data);
              toast.error(
                `Failed to save property: ${error.response.data.message}`
              );
            });
        }
      }, 1000);
    }
  };

  const handleUnsave = () => {
    const token = localStorage.getItem("token");
    if (token && favid) {
      axios
        .get(
          `https://backend.militaryhousingcenter.net/api/favourite/delete/${favid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Unsave attempt with favid:", favid);
          setIsSaved(false);
          setFavid(null);
          toast.success("Property unsaved successfully!");
        })
        .catch((error) => {
          console.error("Error unsaving property:", error.response.data);
          toast.error(
            `Failed to unsave property: ${error.response.data.message}`
          );
        });
    } else {
      toast.warning("You need to be logged in to unsave a sheikh property.");
    }
  };

  const renderForm = () => {
    return (
      <>
        <figure className="col-md-12 member-right">
          <img
            src="../images/x-icon.png"
            alt=""
            onClick={handleClose}
            style={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              position: "absolute",
              zIndex: 1,
              right: 0,
              marginTop: "-25px",
              marginRight: "10px",
            }}
          />
          <h1>
            Saving <span>Property </span>
          </h1>
          <div className="forget-text">
            <p>Please enter your name of the search.</p>
          </div>
          <form
            id="savefavform"
            name="savefavform"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="input-group">
              <div className="input-group-prepend">
                <span
                  className="input-group-text fa fa-pencil"
                  style={{ paddingTop: "12px" }}
                ></span>
              </div>
              <input
                type="text"
                name="savesearchtitle"
                id="savesearchtitle"
                className="form-control"
                placeholder="Enter name of search"
                value={search}
                onChange={handleSearch}
              />
              <input
                type="hidden"
                name="saveproid"
                id="saveproid"
                className="form-control"
                value={prop_id}
              />
            </div>
            <br />
            <button
              type="button"
              id="savesearhtitlebtnn"
              name="savesearhtitlebtnn"
              onClick={handleSave}
              className="read-more"
            >
              <span className="fa fa-heart"></span> &nbsp;&nbsp; Save{" "}
            </button>
          </form>
        </figure>
      </>
    );
  };

  return (
    <div>
      <>
        <img
          alt=""
          src={isSaved ? "images/heart-icon-red.png" : "images/heart-icon.png"}
          onClick={isSaved ? handleUnsave : handleShow}
        />
        <Modal show={show} onHide={handleClose} size="md">
          {renderForm()}
        </Modal>
      </>
    </div>
  );
};

export default FavModal;
