import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactModal from "../modals/ContactModal";
import AuthModal from "../modals/AuthModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
function Home() {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [activeButton, setActiveButton] = useState(1);
  const [searchesCount, setSearchesCount] = useState(0);
  const [favouriteCount,setFavouritesCount] = useState(0);
  const navigate = useNavigate(); // For navigation

  // Function to handle button clicks
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    const uid = localStorage.getItem("uid");
    axios
      .get(`https://backend.militaryhousingcenter.net/api/savedsearches/count/${uid}`)
      .then((response) => {
        const searchCount = response.data.savedsearch_count;
        setSearchesCount(searchCount);
      })
      .catch((error) => {
        console.error("Error fetching saved searches count:", error);
      });
  }, []);
  useEffect(() => {
    const uid = localStorage.getItem("uid");
    axios
      .get(`https://backend.militaryhousingcenter.net/api/favourites/count/${uid}`)
      .then((response) => {
        const favouriteCount = response.data.favourite_count;
        setFavouritesCount(favouriteCount);
      })
      .catch((error) => {
        console.error("Error fetching favourites count:", error);
      });
  }, []);
  

  // Function to handle search
  const handleSearch = () => {
    switch (activeButton) {
      case 1:
        navigate(
          `/SearchResult?search=${encodeURIComponent(search)}&sort=forsale`
        ); // Redirect to the Buy page
        break;
      case 2:
        navigate(
          `/SearchResult?search=${encodeURIComponent(search)}&sort=forrent`
        ); // Redirect to the Rent page
        break;
      case 3:
        navigate(`/AgentSearchResult?search=${encodeURIComponent(search)}`); // Redirect to the Find an Agent page
        break;
      default:
        toast.warning("Please select an option");
    }
  };
  return (
    <div className="home-bg">
      <article id="main-wrapper">
        <header id="main-header" className="sticky-top">
          <section className="header-top">
            <article className="container-fluid">
              <figure className="row top-bar">
                <figure className="col-md-12 text-right">
                  <nav className="top-menu">
                    <li>
                     <Link to="/dashboard?tab=saved-searches">
                          SAVED SEARCHES
                          <span className="badge topbadge themebg">{searchesCount}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard?tab=favoriteProperties">
                          FAVORITES 
                          <span className="badge topbadge themebg">{favouriteCount}</span>
                        </Link>
                    </li>
                    <li>
                      <AuthModal />
                    </li>
                  </nav>
                </figure>
              </figure>

              <div className="home-bar">
                <figure className="row">
                  <div className="bs-example" style={{ paddingLeft: 0 }}>
                    <figure className="top-logo logoback">
                      <Link to="/">
                        <img alt="" src="images/logo5.png" />
                      </Link>
                    </figure>
                    <nav
                      className="navbar navbar-light bg-light mobilenavbtn"
                      style={{
                        width: "20% !important",
                        display: "block",
                        textAlign: "right",
                        marginTop: "15px",
                      }}
                    >
                      <button
                        type="button"
                        className="navbar-toggler"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        <span className="fa fa-bars text-white"></span>
                      </button>
                    </nav>

                    <div
                      className="collapse navbar-collapse mobilenav"
                      id="navbarCollapse"
                    >
                      <li>
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li>
                        <Link to="/dashboardAdvertiser">Va Loan</Link>
                      </li>
                      <li>
                        <Link to="/SearchResult">Relocation</Link>
                      </li>
                      <li>
                        <Link to="/AgentSearchResult">Resources</Link>
                      </li>
                      <li>
                        <a href="/">Advertise</a>
                      </li>
                      <li>
                        <ContactModal />
                      </li>
                    </div>
                  </div>

                  <figure className="col-md-3 logoback">
                    <Link to="/">
                      <img alt="" className="logo" src="images/logo5.png" />
                    </Link>
                  </figure>

                  <figure className="col-md-9">
                    <nav className="main-menu">
                      <li>
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li>
                        <Link to="/dashboardAdvertiser">Va Loan</Link>
                      </li>
                      <li>
                        <Link to="/SearchResult">Relocation</Link>
                      </li>
                      <li>
                        <Link to="/lenders">Lender</Link>
                      </li>
                      <li>
                        <Link to="/AgentSearchResult">Resources</Link>
                      </li>
                      <li>
                        <a href="/">Advertise</a>
                      </li>
                      <li>
                        <ContactModal />
                      </li>
                    </nav>
                  </figure>
                </figure>
              </div>
            </article>
          </section>
        </header>
        <section id="banner-header">
          <div className="container-fluid">
            <div className="col-md-12">
              <figure className="banner-text">
                <h1>
                  For all your military
                  <br />
                  Relocation Needs
                </h1>
              </figure>

              <div className="row justify-content-center text-center pt-3 ">
                <div className="col-md-8 btn-width">
                  <figure className="search-btn nav-pills ">
                    <li className={activeButton === 1 ? "active" : " "}>
                      <button
                        id="home-btn"
                        type="button"
                        className={activeButton === 1 ? "active" : " "}
                        onClick={() => handleButtonClick(1)}
                      >
                        Buy
                      </button>
                    </li>

                    <li className={activeButton === 2 ? "active" : " "}>
                      <button
                        id="home-btn"
                        type="button"
                        className={activeButton === 2 ? "active" : ""}
                        onClick={() => handleButtonClick(2)}
                      >
                        Rent
                      </button>
                    </li>
                    <li className={activeButton === 3 ? "active" : " "}>
                      <button
                        id="home-btn"
                        type="button"
                        className={activeButton === 3 ? "active" : ""}
                        onClick={() => handleButtonClick(3)}
                      >
                        Find an agent
                      </button>
                    </li>
                  </figure>
                </div>
              </div>
              <div className="row justify-content-center search-home">
                <div
                  className=" col-md-8 text center input-group md-form form-sm form-2"
                  style={{ width: "63%" }}
                >
                  <input
                    className="form-control my-0 py-1 red-border"
                    type="text"
                    placeholder="Search by Military Installation, Address, City, County, Zip Code or MLS #"
                    aria-label="Search"
                    onChange={handleSearchChange}
                    value={search}
                  />
                  <div className="input-group-append search-small">
                    <span
                      className="input-group-text search-small  red lighten-3"
                      id="basic-text1"
                      style={{ height: "50px", cursor: "pointer" }}
                      onClick={handleSearch}
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <figure className="home-footer">
            <div className="row">
              <div
                className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-left"
                style={{ marginBottom: "-12px" }}
              >
                <p>© 2020 Military Housing Center, Inc.</p>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-right">
                <nav className="foot-menu">
                  <li>
                    <Link to="/fairHousing">
                      <img alt="" src="images/home-icon.png" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">About</Link>
                  </li>
                  <li>
                    <Link to="/policy">Privacy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms</Link>
                  </li>
                </nav>
              </div>
            </div>
          </figure>
        </section>
      </article>
    </div>
  );
}

export default Home;
