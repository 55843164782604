import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ContactModal from "../modals/ContactModal";
import AuthModal from "../modals/AuthModal";
import AgentCard from "../card/AgentCard";
import useScrollPosition from "./useScrollPosition";
import axios from "axios";
import AgentModal from "../modals/AgentModal";
import LoadingBar from "react-top-loading-bar";
import SaveSearchModal from "../modals/SaveSearchModal";
function AgentSearchResult() {
  const scrollPosition = useScrollPosition();
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [card, setCard] = useState([]);
  const [card1, setCard1] = useState([]);

  const [agentCount, setAgentCount] = useState(0);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [search, setSearch] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [showFilter, setShowFilter] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedSort, setSelectedSort] = useState(
    new URLSearchParams(location.search).get("sort") || "byReviews"
  );
  const [headerText, setHeaderText] = useState("");
  const [visibleCount, setVisibleCount] = useState(4);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null); // Reference for the loader element
  const fetchTimeout = useRef(null);
  const [searchesCount, setSearchesCount] = useState(0);
  const [favouritesCount,setFavouritesCount] = useState(0);
  useEffect(() => {
    const uid = localStorage.getItem("uid");
    axios
    .get(`https://backend.militaryhousingcenter.net/api/favourites/agentcount/${uid}`)
    .then((response) => {
      const favouriteCount = response.data.agent_count;
      setFavouritesCount(favouriteCount);
    })
    .catch((error) => {
      console.error("Error fetching favourite agents count:", error);
    });
  }, []);
  useEffect(() => {
    const uid = localStorage.getItem("uid");
    axios
      .get(`https://backend.militaryhousingcenter.net/api/savedsearches/count/${uid}`)
      .then((response) => {
        const searchCount = response.data.savedsearch_count;
        setSearchesCount(searchCount);
      })
      .catch((error) => {
        console.error("Error fetching saved searches count:", error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://backend.militaryhousingcenter.net/api/agents/titles")
      .then((response) => {
        const initialSelection = {};
        response.data.forEach((item) => {
          initialSelection[item.title_name] = true; // Select all by default
        });
        const urlTitles = new URLSearchParams(location.search).get("titles");
        if (urlTitles) {
          const urlSelectedTitles = urlTitles.split(",");
          Object.keys(initialSelection).forEach((title) => {
            initialSelection[title] = urlSelectedTitles.includes(title);
          });
        }
        setCard(response.data);
        setSelected(initialSelection);
      })
      .catch((error) => {
        console.error("there is an error", error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://backend.militaryhousingcenter.net/api/agents")
      .then((response) => {
        setCard1(response.data.agents);
      })
      .catch((error) => {
        console.error("there is an error", error);
      });
  }, []);
  useEffect(() => {
    setIsNavVisible(scrollPosition < 50);
  }, [scrollPosition]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          // Clear any previous timeout to avoid multiple triggers
          if (fetchTimeout.current) {
            clearTimeout(fetchTimeout.current);
          }

          // Set a timeout to delay the loading of more cards
          fetchTimeout.current = setTimeout(() => {
            setVisibleCount((prevCount) => prevCount + 4);
          }, 500); // 2-second delay
        }
      },
      { threshold: 1.0 }
    );

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
      if (fetchTimeout.current) {
        clearTimeout(fetchTimeout.current);
      }
    };
  }, [hasMore]);

  useEffect(() => {
    // Update the search state when the location changes (i.e., when the component is mounted)
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search");
    if (searchQuery) {
      setSearch(searchQuery);
      setShowFilter(true);
    }
  }, [location.search]);
  // useEffect(() => {
  //   setHasMore(visibleCount < card1.length);
  // }, [visibleCount, card1]);

  const updateURL = () => {
    const params = new URLSearchParams();
    if (search) params.set("search", search);
    if (selectedSort) params.set("sort", selectedSort);

    const selectedTitles = Object.keys(selected).filter((key) => selected[key]);
    if (selectedTitles.length > 0) {
      params.set("titles", selectedTitles.join(","));
    }

    navigate(`${location.pathname}?${params.toString()}`,{replace:true});
  };

  useEffect(updateURL, [search, selectedSort, selected]);

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    const newSelected = { ...selected, [id]: checked };
    setSelected(newSelected);
  };
  const handleCardClick = (id) => {
    navigate(`/AgentSearchResult?profile-popup=${id}`);
  };
  const removeTag = (title) => {
    const newSelected = { ...selected, [title]: false };
    setSelected(newSelected);
  };

  const selectedFilters = Object.keys(selected).filter((key) => selected[key]);
  const allSelected = Object.values(selected).every((value) => value);

  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    setSearch(newSearch);
    setShowFilter(newSearch !== "");
    if (newSearch.includes(",")) {
      const [city, state] = newSearch.split(",").map((s) => s.trim());
      // setHeaderText(` in ${city}${state ? `, ${state}` : ""}`);
      setHeaderText(
        <>
          <span style={{ color: "#c70000" }}>in</span> {city}
          {state ? `, ${state}` : ""}
        </>
      );
    } else if (newSearch) {
      if (
        card1.some(
          (agent) =>
            agent.city.toLowerCase().includes(newSearch.toLowerCase()) ||
            agent.state.toLowerCase().includes(newSearch.toLowerCase())
        )
      ) {
        setHeaderText(` : ${newSearch}`);
      } else {
        setHeaderText(`: ${newSearch}`);
      }
    } else {
      setHeaderText("");
    }
  };
  const clearFilter = () => {
    setSearch("");
    setShowFilter(false);
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation();
  };
  const handleSortChange = (sortOption) => {
    setSelectedSort(sortOption);
  };
  const clearSortFilter = () => {
    setSelectedSort(null);
    setShowFilter(false);
  };
  return (
    <>
      <div>
        <article id="main-wrapper">
          <header id="main-header" className="sticky-top">
            <section className="header-top">
              <article className="container-fluid">
                <figure className="row top-bar">
                  <figure className="col-md-12">
                    <nav className="top-menu term-head text-right">
                      <li>
                        <Link to="/dashboard?tab=saved-searches">
                          SAVED SEARCHES
                          <span className="badge topbadge themebg">{searchesCount}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard?tab=favoriteAgents">
                          FAVORITES 
                          <span className="badge topbadge themebg">{favouritesCount}</span>
                        </Link>
                      </li>
                      <li>
                        <AuthModal />
                      </li>
                      <div>
                        <LoadingBar progress={progress} color="red" />
                      </div>
                    </nav>

                    <nav className="main-menu term-head text-left">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <a href="/">Va Loan</a>
                      </li>
                      <li>
                        <a href="/">Relocation</a>
                      </li>
                      <li>
                        <Link to="/AgentSearchResult">Find An Agent</Link>
                      </li>
                      <li>
                        <Link to="/lenders">Lenders</Link>
                      </li>
                      <li>
                        <Link to="/businessDirectory">Business Directory</Link>
                      </li>
                      <li>
                        <a href="dashboard.html">Advertise</a>
                      </li>
                    </nav>
                  </figure>
                </figure>
                <figure className="row">
                  <div className="bs-example" style={{ paddingLeft: 0 }}>
                    <figure className="top-logo">
                      <Link to="/">
                        <img alt="" src="images/logo.png" />
                      </Link>
                    </figure>
                    <nav
                      className="navbar navbar-light bg-light mobilenavbtn"
                      style={{
                        width: "20% !important",
                        display: "block",
                        textAlign: "right",
                        marginTop: "15px",
                      }}
                    >
                      <button
                        type="button"
                        className="navbar-toggler"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        <span className="fa fa-bars text-white"></span>
                      </button>
                    </nav>
                    <div
                      className="collapse navbar-collapse mobilenav"
                      id="navbarCollapse"
                    >
                      <li>
                        <a href="index.html">Home</a>
                      </li>
                      <li>
                        <a href="/">Va Loan</a>
                      </li>
                      <li>
                        <a href="/">Relocation</a>
                      </li>
                      <li>
                      <Link to="/AgentSearchResult">Find An Agent</Link>
                      </li>
                      <li>
                      <Link to="/lenders">Lenders</Link>
                      </li>
                      <li>
                      <Link to="/businessDirectory">Businesss Directory</Link>
                      </li>
                      <li>
                        <a href="dashboard.html">Advertise</a>
                      </li>
                    </div>
                  </div>
                  <figure className="col-md-3 logoback">
                    <Link to="/">
                      <img alt="" className="logo" src="images/logo5.png" />
                    </Link>
                  </figure>
                  <figure className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <div className="row searchFilter">
                      <div className="col-sm-12">
                        <div className="input-group">
                          <input
                            id="table_filter"
                            type="text"
                            className="form-control"
                            placeholder="Tucson, AZ"
                            onChange={handleSearchChange}
                            aria-label="Text input with segmented button dropdown"
                            value={search}
                          />
                          <div
                            className="input-group-append mr-1"
                            style={{ marginLeft: "-.75rem" }}
                          >
                            <span
                              className="input-group-text red lighten-3"
                              id="basic-text1"
                            >
                              <i
                                className="fa fa-search"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <div className="input-group-btn show">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle dropdown-toggle-split searchtogbtn"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              fdprocessedid="ij49m"
                            >
                              <span className="label-icon">Type</span>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-right hometypefilter"
                              style={{
                                position: "absolute",
                                top: "0px",
                                willChange: "transform",
                                left: "0px",
                                transform: "translate3d(611px, 39px, 0px)",
                              }}
                              x-placement="bottom-end"
                              onClick={handleDropdownClick}
                            >
                              <div className="p-2">
                                <h5 className="mb-3 mt-1">Titles</h5>
                                <ul className="category_filters pb-2 border-bottom">
                                  {card.map((data, index) => (
                                    <li className="active" key={index}>
                                      <div className="custom-control custom-checkbox mr-sm-2">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input checkbox filter"
                                          id={data.title_name}
                                          name="ptype[]"
                                          value={data.title_name}
                                          checked={!!selected[data.title_name]}
                                          onChange={handleCheckboxChange}
                                        />
                                        <label
                                          className="custom-control-label text-dark text-dark p-0 d-block filter"
                                          htmlFor={data.title_name}
                                        >
                                          {data.title_name} {}
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: "smaller" }}
                                            id="counthouse"
                                          >
                                            ({data.agent_count})
                                          </span>
                                        </label>
                                      </div>
                                    </li>
                                  ))}
                                </ul>

                                <h5 className="mb-2 mt-2">Sort</h5>
                                <ul className="category_filters">
                                  <li>
                                    <input
                                      className="cat_type category-input filtersearchtype"
                                      data-label="By Reviews"
                                      id="byreviews"
                                      value="By Reviews"
                                      name="pfor"
                                      type="radio"
                                      checked={selectedSort === "byReviews"}
                                      onChange={() =>
                                        handleSortChange("byReviews")
                                      }
                                    />
                                    <label htmlFor="byreviews" className="mb-0">
                                      By Reviews
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      type="radio"
                                      name="pfor"
                                      className="cat_type category-input filtersearchtype"
                                      id="a-z"
                                      value="A - Z"
                                      checked={selectedSort === "a-z"}
                                      onChange={() => handleSortChange("a-z")}
                                    />
                                    <label
                                      className="category-label"
                                      htmlFor="a-z"
                                    >
                                      By Name A-Z
                                    </label>
                                  </li>
                                </ul>
                              </div>
                              <div className="filtertogglefooter">
                                <button
                                  type="button"
                                  className="btn filterclosebutton done"
                                  // onClick="return closetoggle();"
                                  fdprocessedid="tahz8x"
                                >
                                  Done
                                </button>
                              </div>
                            </div>
                          </div>
                                      <SaveSearchModal/>
                         
                        </div>
                      </div>
                    </div>
                  </figure>
                </figure>
              </article>
            </section>
          </header>

          <article id="result-section">
            <section className="container-fluid">
              <figure className="row">
              <article className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-left sort-bar">
                  <h1>
                    {agentCount}{" "}
                    {agentCount <= 1
                      ? "Real Estate Agent"
                      : "Real Estate Agents"}{" "}
                    <span>{headerText}</span>
                  </h1>
                  <h3>Updated 15 minutes ago</h3>
                </article>
                <article className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right">
                  <a href="https://www.affordablehomeloans.com" target="_blank">
                    <img
                      alt=""
                      className="search-img"
                      src="images/buy-a-home.png"
                    />
                  </a>
                </article>
                <figure className="row mb-4">
                  <div className="col-md-12" id="searchfilterarea" style={{paddingTop:"15px"}}>
                    {selectedSort && (
                      <div className="" id="searchfilterarea">
                        <div className="float-left m-1">
                          <div className="tagtextarea">
                            Sort By:
                            {selectedSort === "all"
                              ? "All"
                              : selectedSort === "byReviews"
                              ? " Reviews"
                              : " Name A-Z"}
                          </div>
                          <button
                            className="tagbtnarea"
                            onClick={clearSortFilter}
                            fdprocessedid="fa7dve"
                          >
                            x
                          </button>
                        </div>
                      </div>
                    )}
                    {showFilter && (
                      <div className="float-left m-1">
                        <div className="tagtextarea">Search: {search}</div>
                        <button
                          className="tagbtnarea"
                          onClick={clearFilter}
                          fdprocessedid="fa7dve"
                        >
                          x
                        </button>
                      </div>
                    )}
                    {!allSelected && (
                      <div className="" id="searchfilterarea">
                        {selectedFilters.map((title) => (
                          <div className="float-left m-1" key={title}>
                            <div className="tagtextarea">Title: {title}</div>
                            <button
                              className="tagbtnarea"
                              onClick={() => removeTag(title)}
                              fdprocessedid="fa7dve"
                            >
                              x
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </figure>
               
              </figure>

              <figure className="row search-section">
                <AgentCard
                  search={search}
                  selected={selected}
                  selectedSort={selectedSort}
                  cardClick={handleCardClick}
                  setProgress={setProgress}
                  onFilteredCountUpdate={setAgentCount}
                  visibleCount={visibleCount} // Pass visibleCount to AgentCard
                  setHasMore={setHasMore}
                />
                {/* {hasMore && (
                  <div ref={loader} style={{color:"green",fontSize:"34px"}} className="loader">
                    Loading more...
                  </div>
                )} */}
              </figure>

              {hasMore && (
                <div ref={loader} className="result-btn text-center">
                  <a className="read-more" href="/">
                    Please Wait...{" "}
                  </a>
                </div>
              )}
              {card1.map((data) => (
                <AgentModal id={data.id} email={data.email} key={data.id} />
              ))}
            </section>
          </article>
        </article>

        <footer id="main-footer">
          <section className="container-fluid">
            <figure className="row">
              <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <h6>Useful Links</h6>
                <nav className="footer-menu">
                  <li>
                    <Link to="/">Business Directory</Link>
                  </li>
                  <li>
                    <Link to="/policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/fairHousing">Fair Housing</Link>
                  </li>
                  <li>
                    <Link to="/">About Us</Link>
                  </li>
                  <li>
                    <Link to="/">Careers</Link>
                  </li>
                  <li>
                    <ContactModal />
                  </li>
                </nav>
              </figure>
              <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <h6>Recent News</h6>
                <article className="address-info">
                  <img alt="" src="images/news-img-1.jpg" />
                  <p>
                    <strong>Selling your property?</strong>
                  </p>
                  <p>
                    <img alt="" src="images/cal-icon.png" />
                    July 8, 2019
                  </p>
                  <p>
                    In todays compectitive market, there are many ways to
                    sell...
                  </p>
                </article>
                <article className="address-info">
                  <img alt="" src="images/news-img-2.jpg" />
                  <p>
                    <strong>Selling your property?</strong>
                  </p>
                  <p>
                    <img alt="" src="images/cal-icon.png" /> July 8, 2019
                  </p>
                  <p>
                    In todays compectitive market, there are many ways to
                    sell...
                  </p>
                </article>
              </figure>
              <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-right">
                <h6>Follow Us</h6>
                <p>
                  Keep up with us on social media for new listings, events and
                  special promotions.
                </p>
                <p>
                  <img alt="" src="images/social-icon-1.jpg" />
                  <img alt="" src="images/social-icon-2.jpg" />
                  <img alt="" src="images/social-icon-3.jpg" />
                  <img alt="" src="images/social-icon-4.jpg" />
                  <img alt="" src="images/social-icon-5.jpg" />
                  <img alt="" src="images/social-icon-6.jpg" />
                </p>
              </figure>
            </figure>
          </section>
        </footer>
        <article id="foot-btm">
          <section className="container-fluid">
            <figure className="row">
              <figure className="col-md-12 footer-bottom">
                <p>
                  MilitaryHousingCenter.com is NOT affiliated with the Dept. of
                  Veterans Affairs or any government agency.
                </p>
                <p>
                  © 2020 Military Housing Center, Inc. All Rights Reserved.{" "}
                </p>
              </figure>
            </figure>
          </section>
        </article>
      </div>
    </>
  );
}

export default AgentSearchResult;
