import React, { useState } from "react";
import { Modal } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const ProfileVideoModal = ({ profileVideoUrl }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  if (!profileVideoUrl) return null;

  return (
    <div>
      <img
        alt="Play Video"
        src="images/bus-play.png"
        onClick={toggle}
        style={{ cursor: "pointer" }}
      />
      <Modal isOpen={modal} toggle={toggle} className="youtube-modal" size="lg">
        <div className="video-responsive">
          <iframe
            width="900"
            height="506"
            src={profileVideoUrl}
            title="Agent Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileVideoModal;
