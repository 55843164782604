import React, { createContext, useState } from "react";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  const openAuthModal = () => setAuthModalOpen(true);
  const closeAuthModal = () => setAuthModalOpen(false);

  return (
    <ModalContext.Provider
      value={{ isAuthModalOpen, openAuthModal, closeAuthModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};
