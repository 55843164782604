import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ProfileVideoModal from "../modals/ProfileVideoModal";
import axios from "axios";
import FavModal from "../modals/FavModal";
const LendersCard = ({
  search,
  selected,
  selectedSort,
  cardClick,
  setProgress,
  onFilteredCountUpdate,
  visibleCount,
  setHasMore
}) => {
  const [card, setCard] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // useEffect(() => {
  //   setProgress(30);

  //   axios
  //     .get("https://backend.militaryhousingcenter.net/api/lender")
  //     .then((response) => {
  //       setProgress(60);
  //       setCard(response.data.agents);
  //       setHasMore(response.data.length > 4);
  //       setProgress(100);
  //     })
  //     .catch((error) => {
  //       console.error("there is an error", error);
  //     });
  // }, []);
  useEffect(() => {
    // Assuming you have a function to get the user's ID or login status
    const isUserLoggedIn = () => {
      // Example: check if user is logged in via localStorage or global state
      return localStorage.getItem("uid"); // adjust this based on your auth system
    };
  
    const fetchAgents = async () => {
      setProgress(30);
  
      let apiURL = "https://backend.militaryhousingcenter.net/api/lender";
      const userId = isUserLoggedIn();
  
      // If user is logged in, modify the API URL to include the user ID
      if (userId) {
        apiURL = `https://backend.militaryhousingcenter.net/api/lender?requid=${userId}`;
      }
  
      try {
        const response = await axios.get(apiURL);
        setProgress(60);
        setCard(response.data.agents);
        setHasMore(response.data.length > 4);
        setProgress(100);
      } catch (error) {
        console.error("there is an error", error);
      }
    };
  
    fetchAgents();
  }, []);
  useEffect(() => {
    setHasMore(visibleCount < card.length);
  }, [visibleCount, card]);

  const filteredCards = useMemo(() => {
    const filtered = card
      .filter((data) => {
        const searchLower = search.toLowerCase();
        if (search.includes(",")) {
          const [city, state] = search
            .split(",")
            .map((s) => s.trim().toLowerCase());
          const matchesCity = city
            ? data.city.toLowerCase().includes(city)
            : true;
          const matchesState = state
            ? data.state.toLowerCase().includes(state)
            : true;
          return matchesCity && matchesState;
        } else {
          // Check if search term matches any of the name, company name, title, city, or state
          return (
            data.name.toLowerCase().includes(searchLower) ||
            data.company.name.toLowerCase().includes(searchLower) ||
            data.title.toLowerCase().includes(searchLower) ||
            data.city.toLowerCase().includes(searchLower) ||
            data.state.toLowerCase().includes(searchLower)
          );
        }
      })
      .filter((data) => {
        const selectedTitles = Object.keys(selected).filter(
          (key) => selected[key]
        );
        return selectedTitles.includes(data.title);
      })
      .sort((a, b) => {
        if (selectedSort === "byReviews") {
          return b.review_count - a.review_count;
        } else if (selectedSort === "a-z") {
          return a.name.localeCompare(b.name);
        } else {
          return 0;
        }
      });
    onFilteredCountUpdate(filtered.length);
    return filtered;
  }, [card, search, selected, selectedSort, onFilteredCountUpdate]);

  return (
    <>
      {filteredCards.slice(0, visibleCount).map((data) => (
        <article
          className="col-xl-3 col-lg-3 col-md-6 col-sm-6 pb-5"
          key={data.id}
        >
          <figure
            className="result-1"
            style={{
              backgroundImage: `url(${data.photo_url})`,
              backgroundSize: "cover",
              padding: "10px 0px 0px 0px",
              position: "relative",
              minHeight: "350px",
              borderRadius: "3px",
            }}
          >
            <figure className="info-top">
              <figure className="info-right" style={{cursor:"pointer"}}>
              <FavModal agentid={data.id} is_favourite={data.is_favorite} favouriteId={data.favid} />
              </figure>
            </figure>
            {/* Pass video URL if available */}
            <ProfileVideoModal profileVideoUrl={data.video} />

            <figure className="result-info">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  cardClick(data.id);
                }}
                className="open-popup"
              >
                <h2>{data.name}</h2>
              </a>

              <p>
                <img alt="" className="star" src="images/stars-img.png" />{" "}
                {data.review_count}
              </p>
              <div style={{ display: "none" }}>
                <p>{data.city}</p>
                <p>{data.state}</p>
              </div>
              <nav className="agent-menu">
                <li>
                  <a href="/" style={{ color: "white" }}>
                    <img alt="" src="images/bld-icon.png" />
                    {data.company.name}
                  </a>
                </li>
                <li>
                  <a href="/" style={{ color: "white" }}>
                    <img alt="" src="images/usr-icon.png" />
                    {data.title}
                  </a>
                </li>
              </nav>
            </figure>
          </figure>
          <figure className="bottom-logo">
            <img alt="" src="images/tel-img.jpg" />
            <p>{data.phone}</p>
          </figure>
        </article>
      ))}
    </>
  );
};

export default LendersCard;
