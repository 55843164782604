import React, { useState } from "react";
import { toast } from "react-toastify";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simple authentication check
    if (username === "admin" && password === "17009exit") {
      onLogin(true); // Successful login
    } else {
      toast.error("Incorrect username or password");
    }
  };

  return (
    <>
      <div id="fullscreen_bg" class="fullscreen_bg">
        <div class="container">
          <form
            class="form-signin"
            method="post"
            style={{ maxWidth: "500px", margin: "0px auto" }}
            onSubmit={handleSubmit}
          >
            <div class="form-signin-heading text-center">
              <img alt="img" src="images/logo5.png" />
              <br />
              <br />
            </div>
            <input
              type="text"
              class=""
              value={username}
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />{" "}
            <br />
            <button
              class="btn btn-lg btn-danger btn-block"
              type="submit"
              name="login"
              style={{ backgroundColor: "#c70000", borderColor: "#c70000" }}
              fdprocessedid="qpn58i"
            >
              Sign In
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
