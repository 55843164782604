import React from "react";
import { Link } from "react-router-dom";
import ContactModal from "../modals/ContactModal";
function DashboardAdvertiser() {
  return (
    <div>
      <article id="main-wrapper">
        <header id="main-header" class="sticky-top">
          <section class="header-top">
            <article class="container-fluid">
              <figure class="row top-bar">
                <figure class="col-md-12">
                  <nav class="top-menu term-head text-right">
                    <li>
                      <a href="/">
                        SAVED SEARCHES <img alt="" src="images/fav-icon.png" />
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        FAVORITES <img alt="" src="images/fav-icon.png" />
                      </a>
                    </li>

                    <li>
                      <div class="dropdown">
                        <button
                          type="button"
                          class="hi-drop dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          <img alt="" src="images/login-icon.png" />
                          Hi Maykel
                        </button>
                        <div
                          class="hi-drop dropdown-menu"
                          style={{ marginRight: "28px" }}
                        >
                          <a class="hi-drop dropdown-item" href="/">
                            Dashboard
                          </a>
                          <a class="hi-drop dropdown-item" href="/">
                            Edit Account
                          </a>
                          <a class="hi-drop dropdown-item" href="/">
                            Logout
                          </a>
                        </div>
                      </div>
                    </li>
                  </nav>

                  <nav class="main-menu term-head text-left">
                    <li>
                      <a href="/">Va Loan</a>
                    </li>
                    <li>
                      <a href="/">Relocation</a>
                    </li>
                    <li>
                      <a href="/">Resources</a>
                    </li>
                    <li>
                      <a href="/">Find An Agent</a>
                    </li>
                    <li>
                      <a href="/">Advertise</a>
                    </li>
                  </nav>
                </figure>
              </figure>
              <figure class="row">
                <div class="bs-example" style={{paddingLeft:0}}>
                  <figure class="top-logo">
                    <Link to="/">
                      <img alt="" src="images/logo.png" />
                    </Link>
                  </figure>
                  <nav
                    class="navbar navbar-light bg-light mobilenavbtn"
                    style={{
                      width: "20% !important",
                      display: "block",
                      textAlign: "right",
                      marginTop: "15px",
                    }}
                  >
                    <button
                      type="button"
                      class="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      <span class="fa fa-bars text-white"></span>
                    </button>
                  </nav>
                  <div
                    class="collapse navbar-collapse mobilenav"
                    id="navbarCollapse"
                  >
                    <li>
                      <a href="/">Va Loan</a>
                    </li>
                    <li>
                      <a href="/">Relocation</a>
                    </li>
                    <li>
                      <a href="/">Resources</a>
                    </li>
                    <li>
                      <a href="/">Find An Agent</a>
                    </li>
                    <li>
                      <a href="/">Advertise</a>
                    </li>
                  </div>
                </div>

                <figure class="col-md-6 logo">
                  <Link to="/">
                    <img alt="" class="logo" src="images/logo.png" />
                  </Link>
                </figure>
                <figure class="col-xl-6 col-lg-12 col-md-12 col-sm-12 searching">
                  <div class="row justify-content-right">
                    <div class=" col-md-12 text-right input-group md-form form-sm form-2 pl-0">
                      <input
                        class="form-control my-0 py-1 red-border das-ser"
                        type="text"
                        placeholder="Search Properties by Military Installation, Address, City, County, Zip Code or MLS #"
                        aria-label="Search"
                        style={{ fontSize: "17px" }}
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text red lighten-3"
                          id="basic-text1"
                        >
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </figure>
              </figure>
            </article>
          </section>
        </header>

        <article id="dashboard-section">
          <section class="container-fluid">
            <figure class="row">
              <figure class="col-xl-3 col-lg-3 col-md-5 col-sm-5 dash-left text-white">
                <figure class="das-img">
                  <img alt="" src="images/dash-user.jpg" />
                </figure>

                <h1>Maykel Morales</h1>
                <h3>VA Lender</h3>

                <nav class="dash-menu">
                  <a href="/">
                    <li>
                      <img alt="" src="images/dashboard-icon-3.png" />
                      Dashboard
                    </li>
                  </a>
                  <a href="/">
                    <li>
                      <img alt="" src="images/dashboard-icon-2.png" />
                      Notifications{" "}
                      <img alt="" class="das-dot" src="images/dash-dot.jpg" />
                    </li>
                  </a>
                  <a href="/">
                    <li>
                      <img alt="" src="images/dashboard-icon-3.png" />
                      My Properties{" "}
                      <img alt="" class="das-dot" src="images/dash-dot.jpg" />
                    </li>
                  </a>
                  <a href="/">
                    <li>
                      <img alt="" src="images/dashboard-icon-4.png" />
                      Favorites{" "}
                      <img alt="" class="das-dot" src="images/dash-dot.jpg" />
                    </li>
                  </a>
                  <a href="/">
                    <li>
                      <img alt="" src="images/dashboard-icon-5.png" />
                      Saved Searches{" "}
                      <img alt="" class="das-dot" src="images/dash-dot.jpg" />
                    </li>
                  </a>
                  <a href="/">
                    <li>
                      <img alt="" src="images/dashboard-icon-6.png" />
                      My Reviews{" "}
                      <img alt="" class="das-dot" src="images/dash-dot.jpg" />
                    </li>
                  </a>
                  <a href="/">
                    <li>
                      <img alt="" src="images/dashboard-icon-7.png" />
                      Account{" "}
                      <img alt="" class="das-dot" src="images/dash-dot.jpg" />
                    </li>
                  </a>
                </nav>
              </figure>
              <figure class="col-xl-9 col-lg-9 col-md-7 col-sm-7 dash-right">
                <h1>Dashboard</h1>
              </figure>
            </figure>
          </section>
        </article>
      </article>
      <footer id="main-footer">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Useful Links</h6>
              <nav className="footer-menu">
                <li>
                  <Link to="/terms">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/policy">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/terms">Business Directory</Link>
                </li>
                <li>
                  <Link to="/fairHousing">Fair Housing</Link>
                </li>
                <li>
                  <Link to="/terms">About Us</Link>
                </li>
                <li>
                  <Link to="/terms">Careers</Link>
                </li>
                <li>
                  {/* <a href="#contact-popup" className="open-popup-link"> */}
                  <ContactModal />
                  {/* </a> */}
                </li>
              </nav>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Recent News</h6>
              <article className="address-info" alt="">
                <img src="images/news-img-1.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
              <article className="address-info">
                <img src="images/news-img-2.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-right">
              <h6>Follow Us</h6>
              <p>
                Keep up with us on social media for new listings, events and
                special promotions.
              </p>
              <p>
                <img src="images/social-icon-1.jpg" alt="" />
                <img src="images/social-icon-2.jpg" alt="" />
                <img src="images/social-icon-3.jpg" alt="" />
                <img src="images/social-icon-4.jpg" alt="" />
                <img src="images/social-icon-5.jpg" alt="" />
                <img src="images/social-icon-6.jpg" alt="" />
              </p>
            </figure>
          </figure>
        </section>
      </footer>
      <article id="foot-btm">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-md-12 footer-bottom">
              <p>
                MilitaryHousingCenter.com is NOT affiliated with the Dept. of
                Veterans Affairs or any government agency.
              </p>
              <p>© 2020 Military Housing Center, Inc. All Rights Reserved. </p>
            </figure>
          </figure>
        </section>
      </article>
    </div>
  );
}

export default DashboardAdvertiser;
