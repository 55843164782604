import React from "react";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import DashBoard from "./pages/Dashboard";
import DashboardAdvertiser from "./pages/DashboardAdvertiser";
import DirectorySearchResult from "./pages/DirectorySearchResult";
import FairHousing from "./pages/FairHousing";
import Policy from "./pages/Policy";
import SearchResult from "./pages/SearchResult";
import Term from "./pages/Terms";
import AgentSearchResult from "./pages/AgentSearchResult";
import Lenders from "./pages/Lenders";
import BusinessDirectory from "./pages/BusinessDirectory";
import "bootstrap/dist/css/bootstrap.min.css";
import { ModalProvider } from "./modals/ModalContext";
import AuthModal from "./modals/AuthModal";
import FavModal from "./modals/FavModal";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (authStatus) => {
    setIsAuthenticated(authStatus);
  };

  return (
    <div>
      <ModalProvider>
        <Router>
          <Routes>
            {/* If not authenticated, redirect all routes to login */}
            {!isAuthenticated ? (
              <>
                <Route path="/" element={<Login onLogin={handleLogin} />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="dashboard" element={<DashBoard />} />
                <Route
                  path="dashboardAdvertiser"
                  element={<DashboardAdvertiser />}
                />
                <Route
                  path="directorySearchResult"
                  element={<DirectorySearchResult />}
                />
                <Route path="fairHousing" element={<FairHousing />} />
                <Route path="policy" element={<Policy />} />
                <Route path="SearchResult" element={<SearchResult />} />
                <Route path="terms" element={<Term />} />
                <Route
                  path="AgentSearchResult"
                  element={<AgentSearchResult />}
                />
                <Route path="lenders" element={<Lenders />} />
                <Route
                  path="businessDirectory"
                  element={<BusinessDirectory />}
                />
                <Route
                  path="/reset-password/:resetToken"
                  element={<ResetPassword />}
                />
                <Route path="*" element={<Home />} />
              </>
            )}

            <Route element={<AuthModal />} />
            <Route element={<FavModal />} />
          </Routes>
        </Router>
      </ModalProvider>
    </div>
  );
}

export default App;
