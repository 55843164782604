import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ModalContext } from "./ModalContext";
import axios from "axios";
import { toast } from 'react-toastify';
const SaveSearchModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [searchSave, setSearchSave] = useState("");
  const [uid, setUid] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [savid, setSavid] = useState(null);
  const { openAuthModal } = useContext(ModalContext);

  const handleSearch = (e) => {
    setSearchSave(e.target.value);
  };
  const fetchSave = (uid, token) => {
    const currentUrl = window.location.href;

    axios
      .get(
        `https://backend.militaryhousingcenter.net/api/savedsearches/result/${uid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Fetched saved searches:", response.data); // Debugging
        const savedSearch = response.data.find(
          (search) => search.search === currentUrl
        );
        if (savedSearch) {
          setIsSaved(true);
          setSavid(savedSearch.savid);
        }
      })
      .catch((error) => {
        console.error("Error fetching saved searches:", error.response.data);
      });
  };
  useEffect(() => {
    const storedUid = localStorage.getItem("uid");
    const token = localStorage.getItem("token");

    if (storedUid && token) {
      setUid(storedUid);
      fetchSave(storedUid, token); // Fetch existing saved searches
    }
  }, []);

  const handleSave = () => {
    const token = localStorage.getItem("token");

    if (!searchSave || searchSave.length < 3) {
      toast.warning("Please enter a valid search title (at least 3 characters).");
      return;
    }

    const currentUrl = window.location.href;
    const saveData = {
      title: searchSave,
      search: currentUrl,
      requid: localStorage.getItem("uid"),
    };

    if (token) {
      axios
        .post(
          "https://backend.militaryhousingcenter.net/api/savedsearch",
          saveData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Save response:", response.data); //
          setIsSaved(true);
          setSavid(response.data.savid);
          handleClose();
          toast.success("Search saved successfully!");
        })
        .catch((error) => {
          console.error("Error saving Search:", error.response.data);
          toast.error(`Failed to save Search: ${error.response.data.message}`);
        });
    } else {
      handleClose();
      openAuthModal();

      const authModalInterval = setInterval(() => {
        const newToken = localStorage.getItem("token");
        const newUid = localStorage.getItem("uid");

        if (newToken && newUid) {
          clearInterval(authModalInterval);
          setUid(newUid);

          axios
            .post(
              "https://backend.militaryhousingcenter.net/api/savedsearch",
              { ...saveData, requid: newUid },
              {
                headers: {
                  Authorization: `Bearer ${newToken}`,
                },
              }
            )
            .then((response) => {
              setIsSaved(true);
              setSavid(response.data.savid);
              toast.success("Search saved successfully after login!");
            })
            .catch((error) => {
              console.error("Error saving Search:", error.response.data);
              toast.error(`Failed to save Search: ${error.response.data.message}`);
            });
        }
      }, 1000);
    }
  };

  const handleUnsave = () => {
    const token = localStorage.getItem("token");
    console.log("save id", savid);
    if (token && savid) {
      axios
        .get(
          `https://backend.militaryhousingcenter.net/api/savedsearch/deactivate/${savid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsSaved(false);
          setSavid(null);
          toast.success("Search unsaved successfully!");
        })
        .catch((error) => {
          console.error("Error unsaving Search:", error.response.data);
          toast.error(`Failed to unsave Search: ${error.response.data.message}`);
        });
    } else {
      toast.warning("You need to be logged in to unsave a Search.");
    }
  };
  const renderForm = () => {
    return (
      <>
        <figure className="col-md-12 member-right">
          <img
            src="../images/x-icon.png"
            alt=""
            onClick={handleClose}
            style={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              position: "absolute",
              zIndex: 1,
              right: 0,
              marginTop: "-25px",
              marginRight: "10px",
            }}
          />
          <h1>
            Saving <span>Search </span>
          </h1>
          <div className="forget-text">
            <p>Please enter your search title.</p>
          </div>
          <form
            id="savefavform"
            name="savefavform"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="input-group">
              <div className="input-group-prepend">
                <span
                  className="input-group-text fa fa-pencil"
                  style={{ paddingTop: "12px" }}
                ></span>
              </div>
              <input
                type="text"
                name="savesearchtitle"
                id="savesearchtitle"
                className="form-control"
                placeholder="Enter search ttitle"
                value={searchSave}
                onChange={handleSearch}
              />
              <input
                type="hidden"
                name="saveproid"
                id="saveproid"
                className="form-control"
                // value={prop_id}
              />
            </div>
            <br />
            <button
              type="button"
              id="savesearhtitlebtnn"
              name="savesearhtitlebtnn"
              onClick={handleSave}
              className="read-more"
            >
              <span className="fa fa-heart"></span> &nbsp;&nbsp; Save{" "}
            </button>
          </form>
        </figure>
      </>
    );
  };
  return (
    <div>
      <>
        <button
          id="searchBtn"
          type="button"
          className={isSaved?"btn btn-secondary btn-search searchsaved" : "btn btn-secondary btn-search"  }
          style={{ marginBottom: "15px"}}
          onClick={isSaved ? handleUnsave : handleShow}
        >
          {" "}
          <span className="label-icon" >
            {isSaved ? "SEARCH SAVED " : "SAVE SEARCH"}
          </span>
        </button>
        <Modal show={show} onHide={handleClose} size="md">
          {renderForm()}
        </Modal>
      </>
    </div>
  );
};

export default SaveSearchModal;
