import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const resetToken = localStorage.getItem('reset_token');
  const navigate = useNavigate();

  const handlePasswordReset = () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    axios.post(`https://backend.militaryhousingcenter.net/api/password/update/${resetToken}?password=${newPassword}`, {
      password: newPassword,
    })
    .then((response) => {
      toast.success("Password updated successfully!");
      navigate("/"); // Navigate to the home page
    })
    .catch((error) => {
      toast.error(error.response ? error.response.data.message : error.message);
    });
  };

  return (
    <>
    <div id="fullscreen_bg" className="fullscreen_bg">
      <div className="container">
        <form
          className="form-signin"
          method="post"
          style={{ maxWidth: "500px", margin: "0px auto" }}
          onSubmit={handlePasswordReset}
        >
          <div className="form-signin-heading text-center">
            <h2>Reset Password</h2>
            <br />
          </div>
          <input
            type="password"
            className=""
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            type="password"
            className=""
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <br />
          <button
            className="btn btn-lg btn-danger btn-block"
            type="button"
            onClick={handlePasswordReset}
            style={{ backgroundColor: "#c70000", borderColor: "#c70000" }}
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
    </>
  );
};

export default ResetPassword;
